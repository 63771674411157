<template>
  <BaseDrawer height-auto-only no-bottom-rounding no-toolbar class="scrollbar-hide" @close="$emit('close')">
    <template #content>
      <div class="w-full h-full flex flex-col gap-10 rounded-t-3xl bg-netural-50 px-48 pt-14"
        :style="{ height: 'calc(100vh - 80px)', minHeight: 'auto' }">
        <div class="flex justify-between items-end">
          <div class="max-w-xl">
            <BaseText type="label" size="lg" class="text-text-muted">
              White Label Share Links
            </BaseText>
            <BaseText size="sm" class="text-text-normal mt-1">
              This feature lets you share your boards with your own branding, giving you full control over how they’re
              presented while maintaining a professional look.
            </BaseText>
          </div>
          <BaseButton primary :disabled="!hasDataChanged" :loading="loading" @click="saveLabelConfig">
            Save Changes
          </BaseButton>
        </div>
        <div class="bg-neutral-25 rounded-2xl p-1 w-full grid grid-cols-12 h-full max-h-128">
          <!-- Customize Config -->
          <div class="col-span-5 bg-white rounded-xl flex flex-col">
            <!-- Logo -->
            <div class="flex p-4 gap-6">
              <img v-if="companyLogo" :src="companyLogo" class="rounded-md w-10 h-10">
              <img v-else src="../../../assets/images/defaultProfileImage.webp" class="rounded-md w-10 h-10">
              <div>
                <BaseText type="label" size="sm">
                  Logo
                </BaseText>
                <BaseText size="sm" class="text-text-normal">
                  We support PNGs and JPEGs.
                </BaseText>
                <div class="flex gap-2 mt-4">
                  <BaseButton outlined @click="handleFileInput">
                    <input ref="fileInput" class="hidden" type="file" @change="handleFileUpload">
                    <div class="flex items-center justify-between gap-1.5 w-full h-full">
                      <UploadIcon color="#000" />
                      <BaseText type="label" size="sm">
                        Upload Logo
                      </BaseText>
                    </div>
                  </BaseButton>
                  <BaseButton class="px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                    :disabled="companyLogo === ''" @click="removeLogo">
                    Remove
                  </BaseButton>
                </div>
              </div>
            </div>
            <!-- Company information -->
            <div class="p-4 flex flex-col gap-1">
              <BaseText type="label" size="sm">
                Company Information
              </BaseText>
              <BaseText size="sm" class="text-text-normal">
                Details to show related to your company
              </BaseText>
              <!-- Company name row -->
              <div class="grid grid-cols-12 items-center mt-2.5">
                <BaseText type="label" size="sm" class="flex-shrink-0 text-text-muted col-span-4">
                  Company Name
                </BaseText>
                <div class="w-full col-span-8 ">
                  <input v-model="companyName" class="w-full px-2 py-1.5 rounded-md form-input"
                    placeholder="Foreplay" />
                </div>
              </div>
              <!-- Company url row -->
              <div class="grid grid-cols-12 items-center mt-1">
                <BaseText type="label" size="sm" class="col-span-4 flex-shrink-0 text-text-muted">
                  Company URL
                </BaseText>
                <div class="w-full col-span-8">
                  <input v-model="companyURL" class="w-full px-2 py-1.5 rounded-md form-input"
                    placeholder="https://foreplay.co" />
                </div>
              </div>
            </div>
            <!-- Styles -->
            <div class="p-4 flex flex-col gap-1">
              <BaseText type="label" size="sm">
                Styles
              </BaseText>
              <BaseText size="sm" class="text-text-normal">
                Customize your brand's share page
              </BaseText>
              <div class="grid grid-cols-12 items-center mt-2.5">
                <BaseText type="label" size="sm" class="col-span-4 flex-shrink-0 text-text-muted">
                  Primary color
                </BaseText>
                <div class="w-full col-span-8">
                  <BaseColorPicker :value="companyColor" @change="(e) => companyColor = e" />
                </div>
              </div>
            </div>
          </div>
          <!-- Preview -->
          <div class="col-span-7 pl-5 pt-5 overflow-hidden rounded-xl">
            <div class="flex gap-2">
              <img src="../../../assets/icons/preview-icon.svg">
              <span>Preview</span>
            </div>
            <!-- Mock preview -->
            <div class="bg-none h-full mt-5 rounded-l-xl relative preview-container">
              <!-- Gradient preview -->
              <div class="absolute top-0 right-0 w-full h-full gradient-container">
                <WhiteLabelGradient :fill="companyColor" />
              </div>
              <div class="flex whitespace-nowrap justify-between text-white py-4 px-7 relative z-50">
                <div class="flex items-center gap-2">
                  <img v-if="companyLogo" :src="companyLogo" class="rounded-md w-5 h-5">
                  <img v-else src="../../../assets/images/defaultProfileImage.webp" class="rounded-md w-5 h-5">
                  <div class="flex gap-1">
                    <BaseText type="label" size="lg">
                      {{ companyName }}
                    </BaseText>
                  </div>
                  <!-- Divider -->
                  <div class="text-neutral-alpha-300 w-min text-center mx-2">
                    |
                  </div>
                  <img v-if="currentUser.avatar" :src="currentUser.avatar" class="rounded-full w-5 h-5">
                  <img v-else src="../../../assets/images/defaultProfileImage.webp" class="rounded-full w-5 h-5">

                  <BaseText type="label" size="sm">
                    {{ currentUser?.name }}
                  </BaseText>
                  <BaseText size="sm" class="text-neutral-alpha-700">
                    shared this board with you.
                  </BaseText>
                </div>
              </div>
              <!-- Stack -->
              <div class="w-full h-full flex gap-5 px-7 z-50 relative">
                <div v-for="row in [1, 2, 3, 4]" :key="row" class="flex flex-col gap-5 w-56 h-full flex-shrink-0 ">
                  <div v-for="col in [1, 2, 3, 4]" :key="`${row}-${col}`"
                    :class="[row % 2 !== 0 ? 'max-h-72' : 'max-h-92']"
                    class="bg-neutral-alpha-200 rounded-xl h-full flex-shrink-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BaseDrawer>
</template>

<script>
import firebase from '@/api/config/FirebaseConfig'
import FirebaseAPI from '@/api/firebase'
import { mapMutations } from 'vuex'
import { nanoid } from 'nanoid'
import WhiteLabelGradient from './WhiteLabelGradient.vue'
import UploadIcon from '../../globals/Icons/UploadIcon.vue'
export default {
  name: 'CustomizeLabelShareDrawer',
  components: {
    WhiteLabelGradient,
    UploadIcon

  },
  props: {
    currentUser: {
      type: Object
    },
    currentTeam: {
      type: Object
    }
  },
  data() {
    return {
      companyName: '',
      companyURL: '',
      companyLogo: '',
      companyColor: '',

      originalData: {
        companyName: '',
        companyURL: '',
        companyLogo: '',
        companyColor: ''
      },

      loading: false
    }
  },
  computed: {
    hasDataChanged() {
      const ogData = this.originalData
      return this.companyName !== ogData.companyName || this.companyURL !== ogData.companyURL || this.companyLogo !== ogData.companyLogo || this.companyColor !== ogData.companyColor
    }
  },
  async mounted() {
    const currentTeam = this.$props.currentTeam
    this.companyName = this.originalData.companyName = currentTeam?.whiteLabel?.companyName || ''
    this.companyURL = this.originalData.companyURL = currentTeam?.whiteLabel?.companyURL || ''
    this.companyLogo = this.originalData.companyLogo = currentTeam?.avatar || ''
    this.companyColor = this.originalData.companyColor = currentTeam?.whiteLabel?.companyColor || '#4c99e1'
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER_TEAM']),
    async uploadNewAvatar() {
      if (this.companyLogo !== this.originalData.companyLogo) {
        const team = this.$props.currentTeam
        const storage = firebase
          .app()
          .storage('gs://foreplay-team-avatars')

        // Delete old avatar if exists
        if (team && team?.avatar) {
          const oldAvatarPath = team.avatar
          const oldAvatarRef = storage.ref().child(
            oldAvatarPath.split(
              'https://storage.googleapis.com/foreplay-team-avatars/'
            )[1]
          )
          try {
            await oldAvatarRef.delete()
          } catch (e) {
            console.log('Error deleting avatar: ', e.message)
          }
        }

        // Handle if logo was removed
        if (this.companyLogo === '') {
          return ''
        }

        // Upload logo to gstorage
        const folderName = nanoid()
        const fileName = nanoid()
        const filePath = `${folderName}/${fileName}`
        const base64Logo = this.companyLogo
        await storage.ref(filePath).putString(base64Logo, 'data_url')

        return `https://storage.googleapis.com/foreplay-team-avatars/${filePath}`
      }
      return undefined
    },
    async saveLabelConfig() {
      if (!this.hasDataChanged) {
        this.$showAlert({
          message: 'No changes were made',
          type: 'error'
        })
        return
      }

      const currentTeam = this.$props.currentTeam
      this.loading = true
      try {
        // Create update object
        let toUpdateBuilder = {}
        // Handle new avatar upload
        const fileLocation = await this.uploadNewAvatar()
        // Conditionally add changed properties
        toUpdateBuilder = {
          ...(fileLocation !== undefined) && { avatar: fileLocation },
          ...(this.companyName !== this.originalData.companyName) && { 'whiteLabel.companyName': this.companyName },
          ...(this.companyURL !== this.originalData.companyURL) && { 'whiteLabel.companyURL': this.companyURL },
          ...(this.companyColor !== this.originalData.companyColor) && { 'whiteLabel.companyColor': this.companyColor }
        }

        await FirebaseAPI.Teams.update(currentTeam.id, toUpdateBuilder)

        const newLabelConfig = {
          companyName: this.companyName,
          companyURL: this.companyURL,
          companyColor: this.companyColor
        }
        const newAvatar = fileLocation !== undefined ? fileLocation : currentTeam?.avatar || ''
        this.SET_USER_TEAM({
          ...currentTeam,
          ...{
            avatar: newAvatar,
            whiteLabel: {
              ...newLabelConfig
            }
          }
        })

        this.originalData = {
          ...newLabelConfig,
          companyLogo: newAvatar
        }

        this.$showAlert({
          message: 'White label updated',
          type: 'success'
        })

        this.loading = false
      } catch (e) {
        this.$showAlert({
          message: 'Error updating white label',
          type: 'error'
        })
        console.log('Error', e.message)
        this.loading = false
      }
    },
    handleFileUpload(event) {
      const mediaFile = event.target.files[0]
      if (!mediaFile) return
      const fileType = mediaFile.type
      if (!(fileType === 'image/png' || fileType === 'image/jpeg')) {
        this.$showAlert({
          message: 'Only JPEG and PNG accepted',
          type: 'error'
        })
        return
      }

      const reader = new FileReader()
      reader.onload = (e) => {
        this.companyLogo = e.target.result
      }
      reader.readAsDataURL(mediaFile)
    },
    removeLogo() {
      this.companyLogo = ''
      this.$refs.fileInput.value = ''
    },
    handleFileInput() {
      this.$refs.fileInput.click()
    }
  }

}
</script>

<style>
.form-input {
  --placeholder-color: #6f7480;
  outline: none;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
  transition: all 150ms;
}

.form-input::placeholder {
  transition: color 150ms ease-in-out;
  color: var(--placeholder-color);
}

.form-input:hover {
  --placeholder-color: #A4ACB9;
}

.form-input:focus-within,
.form-input:focus {
  outline: none;
  --placeholder-color: #A4ACB9;
  box-shadow: 0px 2px 4px 0px rgba(4, 26, 75, 0.2), 0px 0px 2px 2px rgba(0, 56, 108, 0.15);
}

.preview-container {
  width: 1440px;
  border-top-left-radius: 12px;
  overflow: hidden;
}
</style>
